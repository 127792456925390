<template>
  <NavComponent></NavComponent>
  <div class="main-content case-content case-content--catholic">
    <div class="case-wrapper">
      <div class="column">
        <card-component
          direction="column"
          class="case__details"
          data-aos="zoom-in"
        >
          <img
            class="case__author-logo"
            src="@/assets/images/gppc-logo.png"
            alt="General Physician PC"
          />
          <h2 class="case__details-title title">
            Vatica helps improve clinical and financial performance
          </h2>
          <p class="case__description">
            Some providers perceive risk adjustment as a benefit only for health
            insurers. <br>Dr. Charles disagrees with that perspective. GPPC has been
            using Vatica since 2017, with nearly 100 primary care providers.
          </p>
          <img
            class="image-chart"
            src="@/assets/images/gppc-chart.png"
            alt="Diabetes specifity Chart"
          />
        </card-component>
      </div>
      <div class="column" data-aos="zoom-in">
        <card-component direction="column" class="mb-md text-box text-box--start">
          <h2 class="case__details-title title">
            Use incentives to hire needed resources
          </h2>
          <p class="mb-md">
            Thanks to the incentive per completed visit and improvement in RAF
            accuracy, GPPC was able to hire:
          </p>
          <ArrowList :items="listItems"></ArrowList>
        </card-component>

        <card-component direction="column" class="testimonial-card">
          <TestimonialComponent
            name="Richard Charles, MD"
            description="Chief Medical Officer"
            :image="require('@/assets/images/richard-charles.png')"
          >
            “Vatica has helped immensely with our quality program and
            identifying gaps in care,” he notes. “We’ve seen an improvement in
            risk accuracy of about 10% per patient and are grateful to have this
            tool for our large network to use.​”
          </TestimonialComponent>
        </card-component>
      </div>
    </div>
    <CopyrightComponent class="copyright--floating"></CopyrightComponent>
    <DotsNavigation :dots="dots"></DotsNavigation>
  </div>
</template>

<script setup>
import NavComponent from '@/components/NavComponent.vue';
import CardComponent from '@/components/CardComponent.vue';
import TestimonialComponent from '@/components/TestimonialComponent.vue';
import DotsNavigation from '@/components/DotsNavigation.vue';
import CopyrightComponent from '@/components/CopyrightComponent.vue';
import ArrowList from '@/components/ArrowList.vue';

const dots = [
  '/cases/uc-health',
  '/cases/guthrie',
  '/cases/yale',
  '/cases/general-pc',
  '/cases/catholic-medical-partners',
  '/cases/mercyhealth',
];

const listItems = [
  'Two nurses to assist patients with transitions of care',
  'Five additional nurses to help patients with chronic conditions',
  'Two pharmacists',
];
</script>

<style lang="scss" scoped>
.two-columns {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;

  .card {
    width: calc((100% / 2) - 16px);
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }
}

.card {
  flex-grow: 1;
}
</style>

<style lang="scss">
.text-box--start .card__content {
  justify-content: flex-start;
}

.text-box .text-box__title {
  font-size: $fs-hecto;
  color: $blue;
  font-weight: 500;
}
</style>
