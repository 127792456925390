<template>
  <div
    class="flip-card"
    :class="[ active ? 'flip-card--active' : '',  `flip-card--${size}`]"
    @click="() => (active = !active)"
  >
    <div
      class="flip-card__front"
      :class="`flip-card__front--${type}`"
    >
      <div class="flip-card__content">
        <slot name="front"></slot>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <circle cx="15" cy="15" r="15" fill="white" />
        <path d="M11 10L13 8L20 15L13 22L11 20L16 15L11 10Z" fill="none" />
      </svg>
    </div>
    <div class="flip-card__back">
      <slot name="back"></slot>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps(['type', 'size']);
const active = ref(false);
</script>

<style lang="scss" scoped>
.flip-card {
  position: relative;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;

  &__front,
  &__back {
    display: block;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-duration: 0.5s;
    transition-property: transform, opacity;
    height: 100%;
    box-sizing: border-box;
    border-radius: 8px;
  }

  &__front {
    transform: rotateY(0deg);
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 50px;
    opacity: 1;

    .flip-card__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    svg,
    .flip-card__content {
      margin-top: auto;
    }

    &--primary {
      background: $orange;

      path {
        fill: $orange;
      }
    }

    &--secondary {
      background: $light-blue;

      path {
        fill: $light-blue;
      }
    }

    &--tertiary {
      background: $blue;

      path {
        fill: $blue;
      }
    }

    &--quaternary {
      background: $turquoise;

      path {
        fill: $turquoise;
      }
    }
  }

  &__back {
    position: absolute;
    opacity: 0;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform: rotateY(-180deg);
    background: $light-gray;
  }

  &--active {
    .flip-card {
      &__front {
        transform: rotateY(180deg);
        opacity: 0;
      }

      &__back {
        opacity: 1;
        transform: rotateY(0deg);
      }
    }
  }

  &--large {
    .flip-card {
      &__front {
        font-size: $fs-peta;
      }

      &__content {
        max-width: 814px;
      }
    }
  }

  &--small {
    .flip-card {
      &__front {
        font-size: $fs-giga;
      }

      &__content {
        margin-bottom: 50px;
      }
    }
  }
}
</style>
