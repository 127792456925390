<template>
  <NavComponent></NavComponent>
  <div class="main-content moving-content">
    <h1 class="moving__title" data-aos="fade-up">Moving the needle</h1>
    <div class="moving__content">
      <CardComponent direction="column" contentAlign="left" data-aos="zoom-in" data-aos-delay="300">
        <h4 class="turquoise-text card__title">Risk accuracy</h4>
        <p>30% prevalence accuracy improvement across top PCP conditions</p>
        <img
          src="../assets/images/moving-needle-chart.png"
          alt="Risk Accuracy"
          width="100%"
        />
        <div class="chart-disclaimer">
          <span class="medium-text chart-disclaimer__title"
            >Case Study: Vatica Effect on Prevalence Accuracy</span
          ><br />
          <p>
            Prevalence calculated for same 5K+ sample population based on all
            submission sources (green), and with Vatica visit removed (blue)
          </p>
        </div>
      </CardComponent>
      <CardComponent direction="column" contentAlign="left" data-aos="zoom-in" data-aos-delay="300">
        <h4 class="turquoise-text card__title">Quality performance</h4>
        <p>
          Successfully promoting quality gap closure during and after the
          patient visit
        </p>
        <img
          src="../assets/images/moving-needle-chart-2.png"
          alt="Quality Performance"
          width="100%"
        />
        <div class="chart-disclaimer">
          <span class="medium-text chart-disclaimer__title"
            >Case Study: Vatica Gap Closure Rate via Paid Claims</span
          ><br />
          <p>*Test measure shown – not the control measure</p>
          <p>
            **New measure – Interim threshold goal until Star cut points are
            published
          </p>
        </div>
      </CardComponent>
    </div>
    <DotsNavigation :dots="dots"></DotsNavigation>
  </div>
</template>

<script setup>
import NavComponent from '../components/NavComponent.vue';
import CardComponent from '@/components/CardComponent.vue';
import DotsNavigation from '@/components/DotsNavigation.vue';

const dots = ['/gap-closures', '/coding-accuracy', '/moving-needle'];
</script>

<style lang="scss" scoped>
.moving-content {
  padding: 60px 14px;
}

.moving {
  &__title {
    margin-bottom: 30px;
  }

  &__content {
    display: flex;
    gap: 16px;

    .card {
      width: calc((100% / 2) - 8px);
    }
  }
}

.card__title {
  margin-bottom: 0;
}

p {
  margin: 0 0 40px;
}

.chart-disclaimer {
    font-size: 12px;
    margin-top: 40px;

    &__title {
        font-size: $fs-deci;
        display: block;
    }

    p {
        margin: 0;
        font-weight: 400;
    }
}
</style>
