<template>
  <div class="meetings-table">
    <div v-if="!meetings || meetings.length === 0">No meetings</div>
    <template v-if="meetings">
      <div
        class="meetings-table__row"
        v-for="(meeting, index) in meetings"
        :key="index"
        data-aos="fade-in"
        data-aos-delay="600"
      >
        <div class="meetings-table__column meetings-table__details">
          Meeting {{ index + 1 }} | {{ meeting.provider_name }}
        </div>
        <div class="meetings-table__column">
          <ButtonComponent type="primary" :is-link="false" @click="startMeeting(meeting)"
            >Start Meeting</ButtonComponent
          >
        </div>
        <div class="meetings-table__column">
          <ButtonComponent
            type="secondary"
            :path="`/meetings/edit/${meeting.id}`"
            >Edit Meeting</ButtonComponent
          >
        </div>
        <div class="meetings-table__column">
          <ButtonComponent
            type="link"
            :is-link="false"
            @click="deleteMeeting(meeting.id)"
            >Delete Meeting</ButtonComponent
          >
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import ButtonComponent from './ButtonComponent.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import useMeetings from '../composables/meetings.js';
const store = useStore();
const meetingsHelper = useMeetings();

const meetings = computed(function () {
  return store.state.meetings;
});

const deleteMeeting = (id) => {
  meetingsHelper.removeMeeting(id);
};

const startMeeting = (meeting) => {
  meetingsHelper.startMeeting(meeting);
}
</script>

<style lang="scss" scoped>
.meetings-table {
  &__row {
    display: grid;
    gap: 40px;
    align-items: flex-start;
    grid-template-columns: 1fr 230px 230px 240px;

    &:not(:last-child) {
      margin-bottom: 60px;
    }
  }

  &__details {
    color: $turquoise;
    font-size: $fs-kilo;
    font-weight: 500;
  }
}
</style>
