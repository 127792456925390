import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      meetings: JSON.parse(localStorage.getItem('meetings')) ?? [],
      currentMeeting: {},
      globalMessage: ''
    };
  },

  mutations: {
    reset(state) {
      state.meetings = [];
    },

    setMeeting(state, value) {
      state.meetings = value;
    },

    removeMeeting(state, id) {
      let meeting = state.meetings.find((m) => {
        return m.id === id;
      });

      meeting = state.meetings.indexOf(meeting);

      state.meetings.splice(meeting, 1);
    },

    setCurrentMeeting(state, meeting) {
      state.currentMeeting = meeting;
    },

    updateMeeting(state, meeting) {
      let meetingIndex = state.meetings.findIndex((m) => {
        return m.id.toString() === meeting.id;
      })

      state.meetings[meetingIndex] = meeting;
    },

    getCurrentMeeting(state) {
      return state.currentMeeting;
    },

    setGlobalMessage(state, message) {
      state.globalMessage = message;
    }
  },
});
