<template>
  <div
    class="bubble-collapsible"
    :class="`bubble-collapsible--${type} bubble-collapsible--${direction}`"
  >
    <button
      class="bubble-collapsible__button"
      @click="toggleCollapse"
      :class="{ active: !collapsed }"
    >
      <span class="bubble-collapsible__button-index">{{ index }}</span>
      <span v-html="title"></span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        v-if="showIcon"
      >
        <circle cx="15" cy="15" r="15" fill="white" />
        <path d="M11 10L13 8L20 15L13 22L11 20L16 15L11 10Z" fill="#486EB6" />
      </svg>
    </button>
    <div class="bubble-collapsible__content">
      <template v-if="!collapsed">
        <slot></slot>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  title: String,
  type: String,
  index: String,
  direction: String,
  showIcon: {
    type: Boolean,
    default: true,
  },
});
const collapsed = ref(true);

const toggleCollapse = () => {
  collapsed.value = !collapsed.value;
};
</script>

<style lang="scss" scoped>
.bubble-collapsible {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &__button {
    cursor: pointer;
    color: $white;
    padding: 5px 10px 5px 7px;
    width: 100%;
    box-shadow: none;
    border: none;
    font-size: $fs-mega;
    text-align: left;
    font-weight: 500;
    display: grid;
    align-items: center;
    grid-template-columns: 57px 1fr 30px;
    gap: 14px;
    text-decoration: none;
    border-radius: 60px;
    transition: all ease-in-out 0.25s;

    svg {
      margin-left: auto;
    }

    &-index {
      border: 1px solid $white;
      border-radius: 50%;
      width: 57px;
      height: 57px;
      line-height: 57px;
      text-align: center;
      font-size: 40px;
    }
  }

  &--primary .bubble-collapsible__button {
    background: $turquoise;
  }

  &--secondary .bubble-collapsible__button {
    background: $blue;
  }

  &--tertiary .bubble-collapsible__button {
    background: transparent;
    color: $orange;
    font-size: $fs-base;

    .bubble-collapsible__button-index {
      background: $orange;
      color: $white;
    }
  }

  &--quaternary .bubble-collapsible__button {
    background: $orange;

    path {
      fill: $orange;
    }
  }

  &--fifthy .bubble-collapsible__button {
    background: transparent;
    color: $blue;
    font-size: $fs-base;

    .bubble-collapsible__button-index {
      background: $blue;
      color: $white;
    }
  }

  &--top {
    flex-direction: column-reverse;
    justify-content: space-between;

    .bubble-collapsible__content {
      top: auto;
      bottom: calc(100% + 12px);
    }
  }

  &__content {
    padding: 0;
    transition: max-height 0.2s ease-out;
    position: absolute;
    top: calc(100% + 12px);
    width: 100%;
    white-space: normal;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-top: 16px;
      }

      a {
        color: rgba(255, 255, 255, 0.6);
        text-decoration: none;
        font-size: $fs-centi;
        display: block;
        font-weight: 500;
      }
    }
  }
}
</style>
