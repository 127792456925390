<template>
  <NavComponent></NavComponent>
  <div class="main-content well-content">
    <h1 class="well__title" data-aos="fade-up">Reduce time spent completing records</h1>
    <VideoComponent data-aos="zoom-in" data-aos-delay="200"></VideoComponent>
    <CopyrightComponent class="copyright--floating"></CopyrightComponent>
    <DotsNavigation :dots="dots"></DotsNavigation>
  </div>
</template>

<script setup>
import VideoComponent from '@/components/VideoComponent.vue';
import NavComponent from '../components/NavComponent.vue';
import DotsNavigation from '@/components/DotsNavigation.vue';
import CopyrightComponent from '@/components/CopyrightComponent.vue';

const dots = ['/workflow', '/pepn', '365-demo', '/integration', '/integration-workflow'];
</script>

<style lang="scss" scoped>
.well-content {
  padding: 60px 14px;
}

.well {
  &__title {
    margin-bottom: 76px;
  }
}
.copyright--floating {
  bottom: toRem(65);
}
</style>
