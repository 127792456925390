<template>
  <NavComponent></NavComponent>
  <div class="main-content case-content case-content--catholic">
    <div class="case-wrapper">
      <div class="column">
        <card-component direction="column" class="case__details" data-aos="zoom-in">
          <img
            class="case__author-logo"
            src="@/assets/images/mercyhealth-logo.png"
            alt="Catholic Medical Partners"
          />
          <p class="case__description">
            Mercyhealth includes five hospitals, 85 outpatient and specialty
            care clinics and more than 600 physicians in Illinois and Wisconsin.
          </p>
          <img
            class="image-chart"
            src="@/assets/images/mercyhealth-chart.png"
            alt="Diabetes specifity Chart"
          />
        </card-component>
      </div>
      <div class="column" data-aos="zoom-in">
        <div class="two-columns">
          <card-component>
            <div class="statistic-number"><CounterComponent
                number="94"
                suffix="%"
                duration="2500"
              ></CounterComponent></div>
            <p>Vatica visits completed for 94% of 213 eligible patients</p>
          </card-component>
          <card-component>
            <div class="statistic-number blue-text"><CounterComponent
                number="53"
                suffix="%"
                duration="2500"
              ></CounterComponent></div>
            <p>
              Vatica responsible for a 53% increase in vascular disease cases
              documented
            </p>
          </card-component>
        </div>
        <div class="two-columns">
          <card-component>
            <div class="statistic-number orange-text"><CounterComponent
                number="31"
                suffix="%"
                duration="2500"
              ></CounterComponent></div>
            <p>Vatica responsible for a 31% increase in CKD cases documented</p>
          </card-component>
          <card-component>
            <div class="statistic-number turquoise-text"><CounterComponent
                number="49"
                suffix="%"
                duration="2500"
              ></CounterComponent></div>
            <p>
              Increased<br />
              accurate HCC capture by 49%
            </p>
          </card-component>
        </div>
        <card-component direction="column" class="testimonial-card">
          <TestimonialComponent
            name="Anthony Rizzo, DO"
            :image="require('@/assets/images/anthony-rizzo-portrait.png')"
          >
            "Vatica finds details that I don't have easy visibility to. For
            example, Vatica shows me that my patient visited a pulmonologist and
            provides information on the patient's COPD. That saves time for me
            and is so valuable to patient care. <span class="medium-text">Vatica has been a great resource
            with ICD-10 coding, helping us get to a level of specificity that
            most physicians don't have the coding expertise for.</span>"
          </TestimonialComponent>
        </card-component>
      </div>
    </div>
    <DotsNavigation :dots="dots"></DotsNavigation>
  </div>
</template>

<script setup>
import NavComponent from '@/components/NavComponent.vue';
import CardComponent from '@/components/CardComponent.vue';
import TestimonialComponent from '@/components/TestimonialComponent.vue';
import DotsNavigation from '@/components/DotsNavigation.vue';
import CounterComponent from '@/components/CounterComponent.vue';

const dots = ['/cases/paul-linder', '/cases/catholic-medical-partners', '/cases/mercyhealth', '/cases/communicare'];
</script>

<style lang="scss" scoped>
.two-columns {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;

  .card {
    width: calc((100% / 2) - 16px);
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }
}

.card {
  flex-grow: 1;
}
</style>
