import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default function () {
  const router = useRouter();
  const store = useStore();

  const addMeeting = (data) => {
    const meetings = store.state.meetings;

    if (meetings.length < 5) {
      meetings.push(data);
      store.commit('setMeeting', meetings);
      localStorage.setItem('meetings', JSON.stringify(meetings));
      startMeeting(meetings[meetings.length - 1]);
    } else {
      return false;
    }
  };

  const getMeetings = () => {
    return JSON.parse(localStorage.getItem('meetings'));
  };

  const resetMeetings = () => {
    localStorage.removeItem('meetings');
    store.commit('reset');
  };

  const removeMeeting = (id) => {
    store.commit('removeMeeting', id);
    localStorage.setItem('meetings', JSON.stringify(store.state.meetings));
  };

  const startMeeting = (meeting) => {
    store.commit('setCurrentMeeting', meeting);
    router.push('/welcome');
  };

  const getMeetingById = (id) => {
    const meetings = getMeetings();
    let meeting = meetings.find((meeting) => {
      return meeting.id.toString() === id;
    });

    return meeting;
  };

  const updateMeeting = (meeting) => {
    store.commit('updateMeeting', meeting);
    localStorage.setItem('meetings', JSON.stringify(store.state.meetings));
    router.push('/welcome');
  }

  return {
    addMeeting,
    getMeetings,
    resetMeetings,
    removeMeeting,
    startMeeting,
    getMeetingById,
    updateMeeting,
  };
}
