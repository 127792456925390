<template>
  <router-view></router-view>
</template>

<script setup>
import AOS from 'aos';
import 'aos/dist/aos.css'; 
window.addEventListener('resize', function () {
  const fixedWidth = 1440;
  const fixedHeight = 960;

  window.resizeTo(fixedWidth, fixedHeight);
});
AOS.init();
</script>

<style lang="scss">
body {
  background: $light-gray;
  font-family: $base-font;
  padding: 0;
  margin: 0 auto;
  max-width: 1440px;
}

.main-content {
  max-width: calc(100% - 240px);
  margin-left: auto;
  padding: 130px 20px;
  box-sizing: border-box;
  height: 100%;
  position: relative;
}
</style>
