<template>
  <div class="copyright">
    Confidential - Do not distribute &copy; {{ year }} Vatica Health
  </div>
</template>

<script setup>
const year = new Date().getFullYear();
</script>

<style lang="scss" scoped>
.copyright {
    text-align: center;
    font-size: toRem(12);
    color: $dark-gray;
    margin-top: toRem(5);

    &--floating {
        position: absolute;
        bottom: toRem(135);
    }
}
</style>
