<template>
  <NavComponent></NavComponent>
  <div class="main-content case-content case-content--catholic">
    <div class="case-wrapper">
      <div class="column">
        <card-component
          direction="column"
          class="case__details"
          data-aos="zoom-in"
        >
          <img
            class="case__author-logo"
            src="@/assets/images/yale-logo.png"
            alt="Yale New Heaven Health"
          />
          <h2 class="case__details-title title">Better patient care</h2>
          <p class="case__description mb-lg">
            Dr. Williams cited the case of a patient who had aortic
            atherosclerosis noted on a CT of the abdomen. He was not aware of
            the test or the diagnosis, which was flagged prior to the patient’s
            visit on Vatica’s Pre-Encounter Provider Notification. The diagnosis
            has been added to the problem list and is under active management.
          </p>
          <h3 class="blue-text">Quick Facts​</h3>
<ArrowList :items="listItems"></ArrowList>
        </card-component>
      </div>
      <div class="column" data-aos="zoom-in">
        <card-component direction="column" class="mb-md text-box">
          <p>
            <span class="text-box__title">Clear impact on patient care</span><br />
            Dr. Williams says Vatica is one of several coding and documentation
            programs they use from 7 different payers, but <span class="blue-text fw-medium">Vatica tops the list.</span>
          </p>
          <hr />
          <p>
            <span class="text-box__title">Vatica PEPN for the win</span><br />
            “I see notes in the Pre-Encounter Provider Notification from Vatica,
            and I think <span class="blue-text fw-medium">wow, I’m glad that’s back on my radar!</span>” <br />Dr.
            Williams notes.
          </p>
        </card-component>

        <card-component direction="column" class="testimonial-card">
          <TestimonialComponent
            name="Brian Williams, MD​"
            description="Medical Director of Optimization"
            :image="require('@/assets/images/brian-williams.png')"
          >
            "Using Vatica has helped our providers do a better job of assessing
            risk, understanding where the higher risk population is and
            directing services toward that population. For example, we’re
            lowering readmission rates because we have the resources to do
            that."
          </TestimonialComponent>
        </card-component>
      </div>
    </div>
    <CopyrightComponent class="copyright--floating"></CopyrightComponent>
    <DotsNavigation :dots="dots"></DotsNavigation>
  </div>
</template>

<script setup>
import NavComponent from '@/components/NavComponent.vue';
import CardComponent from '@/components/CardComponent.vue';
import TestimonialComponent from '@/components/TestimonialComponent.vue';
import DotsNavigation from '@/components/DotsNavigation.vue';
import CopyrightComponent from '@/components/CopyrightComponent.vue';
import ArrowList from '@/components/ArrowList.vue';

const dots = [
  '/cases/uc-health',
  '/cases/guthrie',
  '/cases/yale',
  '/cases/general-pc',
  '/cases/catholic-medical-partners',
  '/cases/mercyhealth',
];

const listItems = [
  'Not-for-profit multi-specialty group',
  '130+ community practices in Connecticut, Rhode Island and<br> New York',
  '104 clinicians in 28 sites using Vatica'
]
</script>

<style lang="scss" scoped>
.two-columns {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;

  .card {
    width: calc((100% / 2) - 16px);
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }
}

.card {
  flex-grow: 1;
}

.text-box .card__content {
  justify-content: center;
}
</style>
