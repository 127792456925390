<template>
  <div class="video-wrapper video">
    <video
      id="video"
      :src="require('@/assets/videos/vatica_demo.mp4')"
      width="100%"
      poster="../assets/images/video-poster.jpeg"
      @pause="pauseVideo"
      crossorigin="anonymous"
      type="video/mp4"
      preload="auto"
    ></video>
    <div class="video__play" v-if="!isPlaying" @click="playVideo">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="86"
        height="86"
        viewBox="0 0 86 86"
        fill="none"
      >
        <circle cx="43" cy="43" r="43" fill="white" fill-opacity="0.8" />
        <path
          d="M64.2051 43.5891L32.3969 61.9536V25.2247L64.2051 43.5891Z"
          fill="#028D9D"
        />
      </svg>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps(['video', 'poster']);
const isPlaying = ref(false);

const playVideo = (e) => {
  const video = document.getElementById('video');
  video.play();
  video.setAttribute('controls', 'controls');
  isPlaying.value = true;
};

const pauseVideo = () => {
    isPlaying.value = false;
    document.getElementById('video').removeAttribute('controls')
}
</script>

<style lang="scss" scoped>
.video {
  position: relative;

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
}
</style>
