<template>
  <NavComponent></NavComponent>
  <div class="main-content workflow-content">
    <h1 class="workflow__title" data-aos="fade-up">Integration workflow</h1>
    <CardComponent>
      <img
        class="workflow__dot"
        src="../assets/images/workflow-dot.png"
        alt="90+% of work is done by Vatica"
        width="142"
        data-aos="fade-in" data-aos-delay="1000"
      />
      <BubbleComponentVue
        class="floating-bubble"
        title="EMR notification of Vatica Visit"
        type="tertiary"
        index="!"
        :show-icon="false"
        data-aos="fade-in" data-aos-delay="650"
      >
        <div class="workflow__content">
          <div>
            <ul>
              <li>Prospective access to results of pre-visit prep</li>
              <li>
                Option to select codes, update problem list & review
                documentation
              </li>
            </ul>
          </div>
        </div>
      </BubbleComponentVue>
      <div class="bubbles-wrapper">
        <div class="row">
          <BubbleComponentVue
            class="floating-content"
            data-content="Vitals and visit status update Vatica logic in real time"
            title="Patient visit & coding exercise"
            type="quaternary"
            index="2"
            direction="top"
            data-aos="zoom-in" data-aos-delay="250"
          >
            <div class="workflow__content">
              <div>
                <h6 class="orange-text">In EMR documentation & coding</h6>
                <ul>
                  <li>Conditions & care gaps presented</li>
                  <li>
                    PCP accepts, resolves or rejects conditions with MEAT during
                    or after visit
                  </li>
                  <li>2&ndash;4 minutes, 1 time per member per year</li>
                </ul>
              </div>
            </div>
          </BubbleComponentVue>
        </div>
        <div class="row row-between">
          <BubbleComponentVue title="Pre-visit prep" type="primary" index="1" data-aos="zoom-in">
            <div
              class="workflow__content workflow__content--three-quarters workflow__content--space-border"
            >
              <div class="column">
                <h6 class="turquoise-text">Scheduling</h6>
                <ul>
                  <li>Member engagement</li>
                  <li>Sync in-scope appointments</li>
                </ul>
              </div>
              <div class="column">
                <h6 class="turquoise-text">
                  Shortly before visit, Vatica nurse aggregates detailed patient
                  profile
                </h6>
                <ul>
                  <li>Progress notes</li>
                  <li>Consult notes</li>
                  <li>Imaging & labs</li>
                  <li>Demographics</li>
                  <li>Histories</li>
                  <li>Medications</li>
                  <li>Health plan data</li>
                  <li>Scanned documents</li>
                </ul>
              </div>
            </div>
          </BubbleComponentVue>

          <BubbleComponentVue title="Close the loop" type="primary" index="3" data-aos="zoom-in" data-aos-delay="350">
            <div class="workflow__content workflow__content--space-border">
              <div class="column">
                <h6 class="turquoise-text">To PCP</h6>
                <ul>
                  <li>
                    Automated transfer of Dx codes to problem list or
                    reconciliation queue
                  </li>
                  <li>Signed Vatica record attached to EMR visit</li>
                  <li>Program Revenue</li>
                </ul>
              </div>
              <div class="column">
                <h6 class="turquoise-text">To Health Plan</h6>
                <ul>
                  <li>Diagnosis Submission Data</li>
                  <li>Medical Record (PDF)</li>
                </ul>
              </div>
            </div>
          </BubbleComponentVue>
        </div>
      </div>
      <div class="legends" data-aos="fade-in" data-aos-delay="1150" data-aos-anchor=".main-content">
        <div class="legend legend--primary">PCP</div>
        <div class="legend legend--secondary">Vatica</div>
      </div>
    </CardComponent>
    <DotsNavigation :dots="dots"></DotsNavigation>
  </div>
</template>

<script setup>
import BubbleComponentVue from '@/components/BubbleComponent.vue';
import NavComponent from '../components/NavComponent.vue';
import CardComponent from '@/components/CardComponent.vue';
import DotsNavigation from '@/components/DotsNavigation.vue';

const dots = [
  '/workflow',
  '/pepn',
  'well-365',
  '/integration',
  '/integration-workflow',
];
</script>

<style lang="scss" scoped>
.workflow-content {
  padding: 60px 14px;

  .card {
    position: relative;
    padding-top: 190px;
  }

  .bubble-collapsible {
    max-width: 348px;
  }

  .row {
    display: flex;
    justify-content: center;
    gap: 45px;

    &:not(:last-child) {
      margin-bottom: 54px;
    }

    &-between {
      justify-content: space-between;
    }
  }
}

.workflow {
  &__dot {
    position: absolute;
    top: 25px;
    right: 25px;
  }

  &__title {
    margin-bottom: 30px;
  }

  &__content {
    display: flex;
    gap: 8px;
    font-weight: 400;
    font-size: $fs-deci;
    justify-content: center;

    p {
      margin: 0;
    }

    .column {
      width: 50%;
    }

    ul {
      padding-right: 0;
      padding-left: 18px;
      font-size: $fs-deci;
      line-height: 16px;
      font-weight: 400;

      li {
        margin-bottom: 8px;
      }
    }

    &--column {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      .column {
        width: 100%;
      }
    }
  }
}

.legends {
  display: flex;
  gap: 32px;
  justify-content: flex-end;
}

.legend {
  display: inline-flex;

  &::before {
    content: '';
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 5px;
  }

  &--primary::before {
    background: $orange;
  }

  &--secondary::before {
    background: $turquoise;
  }
}

.bubbles-wrapper {
  padding-bottom: 284px;
}

.floating-bubble {
  position: absolute;
  top: 114px;
  left: 50px;
}

.floating-content {
  &::after {
    content: attr(data-content);
    position: absolute;
    top: 180%;
    text-align: center;
    color: $turquoise;
    font-size: $fs-deca;
    font-weight: 500;
    width: 57%;
    left: 50%;
    transform: translateX(-50%);
  }

  &::before {
    content: '';
    display: inline-block;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='43' viewBox='0 0 16 43' fill='none'%3E%3Cpath d='M7.29289 42.7071C7.68342 43.0976 8.31658 43.0976 8.7071 42.7071L15.0711 36.3431C15.4616 35.9526 15.4616 35.3195 15.0711 34.9289C14.6805 34.5384 14.0474 34.5384 13.6569 34.9289L8 40.5858L2.34314 34.9289C1.95262 34.5384 1.31946 34.5384 0.928931 34.9289C0.538406 35.3195 0.538406 35.9526 0.928931 36.3431L7.29289 42.7071ZM7-4.37112e-08L7 42L9 42L9-4.37112e-08L7-4.37112e-08Z' fill='%23028D9D'/%3E%3C/svg%3E")
      center no-repeat;
    width: 16px;
    height: 43px;
    position: absolute;
    top: 110%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>

<style lang="scss">
.workflow-content {
  .card__content {
    display: block;
  }
}

.floating-bubble .bubble-collapsible__content {
  top: 80%;
  left: 70px;
  width: 60%;
}
</style>
