<template>
  <NavComponent></NavComponent>
  <div class="main-content f-opp">
    <h1 class="f-opp__title" data-aos="fade-up">
      Improve clinical & financial performance
    </h1>
    <h2 class="f-opp__subtitle" data-aos="fade-in" data-aos-delay="200">
      {{ currentMeeting.provider_name }}
    </h2>
    <CardComponent
      class="total-card"
      direction="column"
      contentAlign="left"
      data-aos="zoom-in"
    >
      <h4>Total annual opportunity</h4>
      <div class="statistic-number statistic-number--large white-text">
        <CounterComponent
          :number="totalAmount"
          superscript="$"
          duration="2000"
        ></CounterComponent>
      </div>
    </CardComponent>
    <div class="f-opp__content f-opp__content--multi-column">
      <CardComponent data-aos="zoom-in" data-aos-delay="200">
        <div class="column">
          <h4 class="payer-networks__title turquoise-text">
            Associated Payer Network(s)
          </h4>
          <div class="payer-networks">
            <div class="payer-column">
              <div
                class="payer__network"
                v-for="(meeting, index) in currentMeeting.payer_network.slice(
                  0,
                  5
                )"
                :key="index"
              >
                {{ meeting }}
              </div>
            </div>
            <div class="payer-column">
              <div
                class="payer__network"
                v-for="(meeting, index) in currentMeeting.payer_network.slice(
                  5,
                  currentMeeting.payer_network.lenght
                )"
                :key="index"
              >
                {{ meeting }}
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <h4 class="turquoise-text">Covered/Sponsored Lives</h4>
          <div class="statistic-number statistic-number--large blue-text">
            <CounterComponent
              :number="currentMeeting.covered_lives"
              duration="2000"
            ></CounterComponent>
          </div>
        </div>
      </CardComponent>
    </div>
    <div class="f-opp__content f-opp__content--three-column">
      <CardComponent direction="column" data-aos="zoom-in" data-aos-delay="200">
        <h4 class="turquoise-text">Payer-sponsored incentive</h4>
        <div class="statistic-number statistic-number--large">
          <CounterComponent
            :number="sponsoredIncentive"
            superscript="$"
            duration="3500"
          ></CounterComponent>
        </div>
      </CardComponent>
      <CardComponent
        direction="column"
        v-if="currentMeeting.show_bvc"
        data-aos="zoom-in"
        data-aos-delay="200"
      >
        <h4 class="turquoise-text">VBC gainshare PMPY</h4>
        <div class="statistic-number statistic-number--large orange-text">
          <CounterComponent
            :number="vbcGainshare"
            superscript="$"
            duration="4500"
          ></CounterComponent>
        </div>
      </CardComponent>
      <CardComponent direction="column" data-aos="zoom-in" data-aos-delay="200">
        <h4 class="turquoise-text">Billable office visits</h4>
        <div class="statistic-number statistic-number--large green-text">
          <CounterComponent
            :number="billableOfficeVisits"
            superscript="$"
            duration="5500"
          ></CounterComponent>
        </div>
      </CardComponent>
    </div>
    <p class="disclaimer">Shared savings varies by contract terms</p>
    <CopyrightComponent class="copyright--floating"></CopyrightComponent>
  </div>
</template>

<script setup>
import NavComponent from '@/components/NavComponent.vue';
import CardComponent from '@/components/CardComponent.vue';
import CounterComponent from '@/components/CounterComponent.vue';
import CopyrightComponent from '@/components/CopyrightComponent.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();
const currentMeeting = store.state.currentMeeting;

const sponsoredIncentive = computed(() => {
  return currentMeeting.covered_lives * 150;
});

const vbcGainshare = computed(() => {
  if (!currentMeeting.show_bvc) {
    return 0;
  }

  return currentMeeting.covered_lives * 550;
});

const billableOfficeVisits = computed(() => {
  return currentMeeting.covered_lives * 0.2 * 130;
});

const totalAmount = computed(() => {
  return (
    sponsoredIncentive.value + vbcGainshare.value + billableOfficeVisits.value
  );
});
</script>

<style lang="scss" scoped>
.f-opp {
  padding: 60px 14px;
  position: relative;

  &__title {
    margin-bottom: 86px;
  }

  &__subtitle {
    margin-bottom: 22px;
    margin-top: 170px;
  }

  &__content {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    gap: 18px;

    .card {
      width: 100%;
    }
  }

  .payer-networks {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 22px;
    justify-content: space-between;
  }

  .payer-column {
    width: calc((100% / 2) - 22px);
  }

  .payer__network {
    padding-bottom: 20px;
    margin-bottom: 20px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(99, 100, 102, 0.06);
    }
  }

  .total-amount {
    font-size: $fs-mega;
    text-align: right;
    margin-top: 28px;
    border-top: 1px solid rgba(99, 100, 102, 0.2);
    padding-top: 22px;
  }
}

.total-card {
  position: absolute;
  top: 60px;
  right: 14px;
  background: $turquoise;
  color: $white;
}

.copyright--floating {
  bottom: toRem(30);
}

.disclaimer {
  position: absolute;
  right: toRem(24);
  bottom: toRem(30);
  margin: 0;
  font-size: toRem(12);
  color: $dark-gray;
}
</style>

<style lang="scss">
.f-opp__content {
  .card__content {
    align-items: flex-start;
  }
}

.f-opp__content--multi-column {
  .card__content {
    justify-content: space-between;
    padding-bottom: 90px;

    h4 {
      margin-bottom: 32px;
    }

    .statistic-number {
      line-height: 108px;
    }

    > .column:first-of-type {
      width: 70%;
    }
  }
}
</style>
