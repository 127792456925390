<template>
  <div class="counter">
    <sup v-if="superscript">{{ superscript }}</sup>{{ displayNumber }}<span v-if="text">{{ text }}</span><sup v-if="suffix">{{ suffix }}</sup>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
const props = defineProps(['number', 'superscript', 'duration', 'suffix', 'text', 'prefix']);
const frameDuration = 1000 / 60;
const totalFrames = Math.round(props.duration / frameDuration);
const easeOutQuad = (t) => t * (2 - t);

let displayNumber = ref(0);

const animateCounter = () => {
  let frame = 0;
  const countTo = parseInt(props.number, 10);
  console.log(props.number);

  const counter = setInterval(() => {
    frame++;
    const progress = easeOutQuad(frame / totalFrames);
    const currentCount = Math.round(countTo * progress);

    if (parseInt(displayNumber.value, 10) !== currentCount) {
      displayNumber.value = currentCount.toLocaleString('en-US');
    }

    // If we’ve reached our last frame, stop the animation
    if (frame === totalFrames) {
      clearInterval(counter);
    }
  }, frameDuration);
};

onMounted(() => {
  animateCounter();
});
</script>
