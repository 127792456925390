<template>
  <NavComponent></NavComponent>
  <div class="main-content case-content case-content--communicare">
    <div class="case-wrapper">
      <div class="column">
        <card-component direction="column" class="case__details" data-aos="zoom-in">
          <img
            class="case__author-logo"
            src="@/assets/images/communicare-logo.png"
            alt="Catholic Medical Partners"
          />
          <p class="case__description">
            CommuniCare, a Federally Qualified Health Center (FQHC), offers
            integrated primary and specialty care to patients, with multiple
            clinic locations in Texas.
          </p>
        </card-component>
      </div>
      <div class="column" data-aos="zoom-in">
        <div class="three-column">
          <card-component direction="column">
            <div class="statistic-number"><CounterComponent
                number="43"
                suffix="%"
                duration="2500"
              ></CounterComponent></div>
            <p>Increased accurate HCC capture by 43%</p>
          </card-component>
          <card-component direction="column">
            <div class="statistic-number orange-text"><CounterComponent
                number="37"
                suffix="%"
                duration="2500"
              ></CounterComponent></div>
            <p>Vatica responsible for a 37% increase in CKD cases documented</p>
          </card-component>
          <card-component direction="column">
            <div class="statistic-number blue-text"><CounterComponent
                number="20"
                suffix="%"
                duration="1500"
              ></CounterComponent></div>
            <p>
              Vatica responsible for a 20% increase in COPD cases documented
            </p>
          </card-component>
        </div>
        <card-component direction="column" class="testimonial-card">
          <TestimonialComponent
            name="Janie Reddy, DNP, NP"
            :image="require('@/assets/images/janie-reddy-portrait.png')"
          >
            "Vatica helps us address coding and documentation in a very timely
            fashion, as well as quality metrics. It's so simple for clinicians
            to review and sign-off on the information. It's the easiest system
            to use from our payers."
          </TestimonialComponent>
        </card-component>
      </div>
    </div>
    <DotsNavigation :dots="dots"></DotsNavigation>
  </div>
</template>

<script setup>
import NavComponent from '@/components/NavComponent.vue';
import CardComponent from '@/components/CardComponent.vue';
import TestimonialComponent from '@/components/TestimonialComponent.vue';
import DotsNavigation from '@/components/DotsNavigation.vue';
import CounterComponent from '@/components/CounterComponent.vue';

const dots = ['/cases/paul-linder', '/cases/catholic-medical-partners', '/cases/mercyhealth', '/cases/communicare'];
</script>

<style lang="scss" scoped>
.three-column {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;

  .card {
    width: calc((100% / 3) - 16px);
    padding-left: 24px;
    padding-right: 24px;
    min-height: 357px;
    box-sizing: border-box;
    align-items: flex-start;
    padding-top: 70px;
  }
}

.card {
  flex-grow: 1;
}
</style>

<style lang="scss">
.case-content--communicare {
    .case__details .card__content {
        justify-content: flex-start;
    }
}
</style>