<template>
  <NavComponent></NavComponent>
  <div class="main-content welcome-content" data-aos="zoom-in">
    <FlipCardComponent type="primary" size="large">
      <template v-slot:front>
        Improve financial and clinical performance with comprehensive coding and
        documentation
      </template>
      <template v-slot:back>
        <div class="mansonry-grid">
          <CardComponent>
            <span class="statistic-number">100<sup>%</sup></span>
            of program cost covered by sponsoring plan
          </CardComponent>
          <CardComponent>
            <span class="statistic-number">$150</span>
            program <br />revenue per completed visit
          </CardComponent>
          <CardComponent>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="104"
              height="98"
              viewBox="0 0 104 98"
              fill="none"
            >
              <path
                d="M39.3102 73.82C38.6802 73.82 38.0502 73.68 37.4602 73.4C35.9402 72.68 35.0002 71.19 35.0002 69.51V68.35H34.8202C31.3102 68.35 28.4502 65.49 28.4502 61.98V39.39C28.4502 35.88 31.3102 33.02 34.8202 33.02H68.2802C71.7902 33.02 74.6502 35.88 74.6502 39.39V61.98C74.6502 65.49 71.7902 68.35 68.2802 68.35H47.5302L42.0402 72.84C41.2502 73.49 40.2902 73.82 39.3202 73.82H39.3102ZM34.8102 36.53C33.2302 36.53 31.9402 37.82 31.9402 39.4V61.99C31.9402 63.57 33.2302 64.86 34.8102 64.86H36.7402C37.7102 64.86 38.4902 65.64 38.4902 66.61V69.52C38.4902 69.98 38.8102 70.18 38.9502 70.25C39.0902 70.31 39.4502 70.44 39.8102 70.15L45.7802 65.26C46.0902 65 46.4802 64.86 46.8902 64.86H68.2702C69.8502 64.86 71.1402 63.57 71.1402 61.99V39.4C71.1402 37.82 69.8502 36.53 68.2702 36.53H34.8102Z"
                fill="#4ACAFA"
              />
              <path
                d="M89.4399 66.83C88.4699 66.83 87.6899 66.05 87.6899 65.08V39.64C87.6899 19.71 71.4799 3.5 51.5499 3.5C31.6199 3.5 15.3999 19.71 15.3999 39.64V65.08C15.3999 66.05 14.6199 66.83 13.6499 66.83C12.6799 66.83 11.8999 66.05 11.8999 65.08V39.64C11.8999 17.78 29.6799 0 51.5399 0C73.3999 0 91.1799 17.78 91.1799 39.64V65.08C91.1799 66.05 90.3999 66.83 89.4299 66.83H89.4399Z"
                fill="#4ACAFA"
              />
              <path
                d="M13.65 66.83H8.54C3.83 66.83 0 63 0 58.29V44.45C0 39.74 3.83 35.91 8.54 35.91H13.65C14.62 35.91 15.4 36.69 15.4 37.66V65.09C15.4 66.06 14.62 66.84 13.65 66.84V66.83ZM8.54 39.4C5.76 39.4 3.5 41.66 3.5 44.44V58.28C3.5 61.06 5.76 63.32 8.54 63.32H11.9V39.39H8.54V39.4Z"
                fill="#4ACAFA"
              />
              <path
                d="M94.5402 66.83H89.4302C88.4602 66.83 87.6802 66.05 87.6802 65.08V37.65C87.6802 36.68 88.4602 35.9 89.4302 35.9H94.5402C99.2502 35.9 103.08 39.73 103.08 44.44V58.28C103.08 62.99 99.2502 66.82 94.5402 66.82V66.83ZM91.1902 63.33H94.5502C97.3302 63.33 99.5902 61.07 99.5902 58.29V44.45C99.5902 41.67 97.3302 39.41 94.5502 39.41H91.1902V63.34V63.33Z"
                fill="#4ACAFA"
              />
              <path
                d="M37.2199 93.28H36.3299C22.8599 93.28 11.8999 82.32 11.8999 68.85V65.08C11.8999 64.11 12.6799 63.33 13.6499 63.33C14.6199 63.33 15.3999 64.11 15.3999 65.08V68.85C15.3999 80.39 24.7899 89.78 36.3299 89.78H37.2199C38.1899 89.78 38.9699 90.56 38.9699 91.53C38.9699 92.5 38.1899 93.28 37.2199 93.28Z"
                fill="#4ACAFA"
              />
              <path
                d="M54.0999 97.06H48.9799C46.2899 97.06 44.1099 94.87 44.1099 92.19V90.87C44.1099 88.18 46.2999 86 48.9799 86H54.0999C56.7899 86 58.9699 88.19 58.9699 90.87V92.19C58.9699 94.88 56.7799 97.06 54.0999 97.06ZM48.9799 89.5C48.2199 89.5 47.6099 90.12 47.6099 90.87V92.19C47.6099 92.95 48.2299 93.56 48.9799 93.56H54.0999C54.8599 93.56 55.4699 92.94 55.4699 92.19V90.87C55.4699 90.11 54.8499 89.5 54.0999 89.5H48.9799Z"
                fill="#4ACAFA"
              />
              <path
                d="M51.73 53.2399C51.27 53.2399 50.82 53.0499 50.49 52.7199C50.17 52.3999 49.98 51.9499 49.98 51.4799C49.98 51.3699 49.98 51.2499 50.02 51.1399C50.04 51.0299 50.07 50.9199 50.12 50.8199C50.16 50.7099 50.21 50.6099 50.28 50.5099C50.34 50.4199 50.41 50.3299 50.49 50.2499C51.15 49.5899 52.32 49.5899 52.97 50.2499C53.29 50.5799 53.48 51.0299 53.48 51.4799C53.48 51.5999 53.48 51.7099 53.45 51.8299C53.42 51.9399 53.39 52.0499 53.35 52.1499C53.3 52.2599 53.25 52.3599 53.19 52.4599C53.12 52.5499 53.05 52.6399 52.97 52.7199C52.89 52.7999 52.8 52.8799 52.7 52.9399C52.61 52.9999 52.51 53.0599 52.4 53.0999C52.29 53.1399 52.18 53.1799 52.07 53.1999C51.96 53.2199 51.84 53.2399 51.73 53.2399Z"
                fill="#4ACAFA"
              />
              <path
                d="M60.25 53.2399C59.79 53.2399 59.34 53.0499 59.01 52.7199C58.69 52.3999 58.5 51.9399 58.5 51.4899C58.5 51.0399 58.69 50.5799 59.01 50.2499C59.67 49.5899 60.83 49.5899 61.49 50.2499C61.81 50.5799 62 51.0299 62 51.4899C62 51.9499 61.81 52.3999 61.49 52.7199C61.17 53.0399 60.71 53.2399 60.25 53.2399Z"
                fill="#4ACAFA"
              />
              <path
                d="M43.21 53.2399C43.1 53.2399 42.98 53.2399 42.87 53.1999C42.76 53.1799 42.65 53.1499 42.54 53.0999C42.44 53.0599 42.33 52.9999 42.24 52.9399C42.14 52.8799 42.05 52.7999 41.97 52.7199C41.89 52.6399 41.82 52.5499 41.75 52.4599C41.69 52.3599 41.64 52.2599 41.59 52.1599C41.55 52.0499 41.52 51.9399 41.49 51.8299C41.47 51.7099 41.46 51.5999 41.46 51.4799C41.46 51.3699 41.46 51.2599 41.49 51.1399C41.52 51.0299 41.55 50.9199 41.59 50.8199C41.64 50.7099 41.69 50.6099 41.75 50.5099C41.82 50.4199 41.89 50.3299 41.97 50.2499C42.05 50.1699 42.14 50.0899 42.24 50.0299C42.33 49.9699 42.44 49.9099 42.54 49.8699C42.65 49.8299 42.76 49.7899 42.87 49.7699C43.44 49.6499 44.04 49.8399 44.45 50.2499C44.53 50.3299 44.6 50.4199 44.67 50.5099C44.73 50.6099 44.78 50.7099 44.83 50.8199C44.87 50.9199 44.9 51.0299 44.93 51.1399C44.95 51.2499 44.96 51.3699 44.96 51.4799C44.96 51.5999 44.95 51.7099 44.93 51.8299C44.9 51.9399 44.87 52.0499 44.83 52.1599C44.78 52.2599 44.73 52.3599 44.67 52.4599C44.6 52.5499 44.53 52.6399 44.45 52.7199C44.12 53.0499 43.67 53.2399 43.21 53.2399Z"
                fill="#4ACAFA"
              />
            </svg>
            Ongoing <br />dedicated<br />
            support
          </CardComponent>
          <CardComponent>
            <span class="statistic-number">2 – 4</span>
            minutes per patient per year
          </CardComponent>
          <CardComponent type="center">
            Improve financial and clinical performance with comprehensive coding
            and documentation
          </CardComponent>
          <CardComponent direction="column">
            <span class="statistic-number">2 weeks</span>
            onboarding time
          </CardComponent>
          <CardComponent direction="column" class="two-rows">
            <div class="flex">
              <span class="statistic-number"><sup>#</sup>1</span>
              in risk <br />adjustment
            </div>
            <div class="logos">
              <img
                src="../assets/images/klas.png"
                alt="Best in Klas"
                width="147"
              />
              <img src="../assets/images/pim.jpg" alt="PIM" />
            </div>
          </CardComponent>
          <CardComponent>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="81"
              height="103"
              viewBox="0 0 81 103"
              fill="none"
            >
              <path
                d="M40.34 102.89C40.08 102.89 39.81 102.83 39.57 102.71L21.84 93.97C8.37 87.33 0 73.88 0 58.87V18.97C0 18.13 0.6 17.41 1.42 17.25C19.48 13.78 31.45 8.47 39.08 0.54C39.41 0.2 39.87 0 40.34 0C40.82 0 41.27 0.19 41.6 0.54C49.23 8.48 61.19 13.79 79.26 17.25C80.08 17.41 80.68 18.13 80.68 18.97V58.87C80.68 73.89 72.31 87.34 58.84 93.97L41.11 102.71C40.87 102.83 40.6 102.89 40.34 102.89ZM3.5 20.41V58.87C3.5 72.54 11.12 84.79 23.38 90.83L40.34 99.19L57.3 90.83C69.56 84.79 77.18 72.54 77.18 58.87V20.41C60.08 16.98 48.29 11.8 40.34 4.22C32.39 11.8 20.59 16.98 3.5 20.41Z"
                fill="#4ACAFA"
              />
              <path
                d="M37.9602 69.3599C37.5002 69.3599 37.0502 69.1799 36.7202 68.8499L19.7502 51.8799C19.4202 51.5499 19.2402 51.1099 19.2402 50.6399C19.2402 50.1699 19.4202 49.7299 19.7502 49.3999L25.8302 43.3199C26.1602 42.9899 26.6002 42.8099 27.0702 42.8099C27.5302 42.8099 27.9802 42.9899 28.3102 43.3199L37.9602 52.9699L56.9002 34.0299C57.5602 33.3699 58.7202 33.3699 59.3702 34.0299L65.4502 40.1099C65.7802 40.4399 65.9602 40.8799 65.9602 41.3499C65.9602 41.8199 65.7802 42.2599 65.4502 42.5899L39.1902 68.8499C38.8602 69.1799 38.4202 69.3599 37.9502 69.3599H37.9602ZM23.4702 50.6499L37.9602 65.1399L61.7402 41.3599L58.1302 37.7499L39.1902 56.6899C38.8602 57.0199 38.4202 57.1999 37.9502 57.1999C37.4902 57.1999 37.0402 57.0199 36.7102 56.6899L27.0602 47.0399L23.4502 50.6499H23.4702Z"
                fill="#4ACAFA"
              />
            </svg>
            Zero risk
          </CardComponent>
        </div>
      </template>
    </FlipCardComponent>
    <CopyrightComponent class="copyright--floating"></CopyrightComponent>
    <DotsNavigation :dots="dots"></DotsNavigation>
  </div>
</template>

<script setup>
import NavComponent from '../components/NavComponent.vue';
import FlipCardComponent from '../components/FlipCardComponent.vue';
import CardComponent from '../components/CardComponent.vue';
import DotsNavigation from '@/components/DotsNavigation.vue';
import CopyrightComponent from '@/components/CopyrightComponent.vue';

const dots = ['/welcome', '/value-prop'];
</script>

<style lang="scss" scoped>
.flip-card {
  min-height: 727px;
  height: 100%;
}

.mansonry-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 232px 232px 232px;
  gap: 16px;
  height: 100%;

  .two-rows {
    grid-column-start: 3;
    grid-row-start: 2;
    grid-row-end: span 2;
  }

  .logos {
    display: grid;
    gap: 14px 50px;
    justify-content: center;
    margin-top: 30px;

    img {
      display: flex;
      grid-column-start: 1;
      grid-column-end: span 2;
      justify-self: center;

      &:last-child {
        margin-top: toRem(36);
      }
    }
  }
}
</style>

<style lang="scss">
.welcome-content {
  .flip-card__front {
    position: absolute;
    width: 100%;
  }
}
</style>
