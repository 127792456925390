<template>
  <NavComponent></NavComponent>
  <div class="main-content case-content">
    <div class="case-wrapper">
      <div class="column">
        <card-component direction="column" class="case__details" data-aos="zoom-in">
          <h1>Dr. Paul Linder<br> & Associates</h1>
          <p class="case__description">
            Dr. Linder is an internist and solo practitioner in Pittsburgh. He
            cares for an elderly population—many are over age 90—with high
            acuity.
          </p>
          <img
            class="image-chart"
            src="@/assets/images/paul-linder-chart.png"
            alt="Diabetes specifity Chart"
          />
        </card-component>
      </div>
      <div class="column">
        <div class="three-column" data-aos="zoom-in">
          <card-component direction="column">
            <div class="statistic-number">
              <CounterComponent
                number="79"
                suffix="%"
                duration="5500"
              ></CounterComponent>
            </div>
            <p>Vatica visits completed for 79% of 169 eligible patients</p>
          </card-component>
          <card-component direction="column">
            <div class="statistic-number orange-text">
              <CounterComponent
                number="21"
                suffix="%"
                duration="3500"
              ></CounterComponent></div>
            <p>Vatica responsible for a 21% increase in CKD cases documented</p>
          </card-component>
          <card-component direction="column">
            <div class="statistic-number blue-text"><CounterComponent
                number="16"
                suffix="%"
                duration="3500"
              ></CounterComponent></div>
            <p>
              Vatica responsible for a 16% increase in vascular disease cases
              documented
            </p>
          </card-component>
        </div>
        <card-component direction="column" class="testimonial-card" data-aos="zoom-in">
          <TestimonialComponent
            name="Dr. Paul Linder"
            :image="require('@/assets/images/paul-linder-portrait.png')"
          >
            "Vatica identifies consults I don't have visibility into. The data
            is very good and identifies information I wasn't aware of with my
            patients. It's the best solution of several I have used, including
            those provided by EHR vendors ...
            <span class="medium-text"
              >Vatica makes it easier to see the full picture with each patient
              and coordinate their care. And the financial impact is
              significant.</span
            >"
          </TestimonialComponent>
        </card-component>
      </div>
    </div>
    <DotsNavigation :dots="dots"></DotsNavigation>
  </div>
</template>

<script setup>
import NavComponent from '@/components/NavComponent.vue';
import CardComponent from '@/components/CardComponent.vue';
import TestimonialComponent from '@/components/TestimonialComponent.vue';
import DotsNavigation from '@/components/DotsNavigation.vue';
import CounterComponent from '@/components/CounterComponent.vue';

const dots = [
  '/cases/paul-linder',
  '/cases/catholic-medical-partners',
  '/cases/mercyhealth',
  '/cases/communicare',
];
</script>

<style lang="scss" scoped>
.three-column {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;

  .card {
    width: calc((100% / 3) - 16px);
    padding-left: 24px;
    padding-right: 24px;
    min-height: 357px;
    box-sizing: border-box;
    align-items: flex-start;
    padding-top: 70px;
  }
}

.card {
  flex-grow: 1;
}
</style>

<style lang="scss">
.case-content {
  .case-wrapper {
    display: grid;
    grid-template-columns: 456px 1fr;
    gap: 16px;
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }

  .card__content {
    text-align: left;
    font-weight: 400;
    align-items: flex-start;
  }

  p {
    margin: 0;
  }
}

.case {
  &__details {
    .image-chart {
      margin-top: 45px;
    }
  }

  &__description {
    max-width: 85%;
  }
}

.three-column,
.two-columns {
  .card__content {
    font-weight: 500;
  }
}
</style>
