<template>
  <NavComponent></NavComponent>
  <div class="main-content coding-content">
    <h1 class="coding-content__title" data-aos="fade-up">
      Vatica improves prevalence and specificity accuracy
    </h1>
    <div class="coding-content__content">
      <div class="column column--large" data-aos="zoom-in" data-aos-delay="300">
        <FlipCardComponent type="tertiary">
          <template v-slot:front>
            30% prevalence accuracy improvement
          </template>
          <template v-slot:back>
            <div class="coding-content__card">
              <h4 class="turquoise-text">
                30% prevalence accuracy improvement
              </h4>
              <p>HCC prevalence - Top 10 PCP focus conditions</p>
              <img
                src="../assets/images/coding-chart.png"
                alt="30% prevalence accuracy improvement"
                width="100%"
              />
            </div>
          </template>
        </FlipCardComponent>
      </div>
      <div class="column column--vertical-half" data-aos="zoom-in" data-aos-delay="500">
        <FlipCardComponent type="primary">
          <template v-slot:front>
            Specificity improvement demonstrated by shift in diabetes prevalence
            to HCC 18 (with complications)
          </template>
          <template v-slot:back>
            <div class="coding-content__card">
              <h4 class="turquoise-text">
                Specificity improvement demonstrated by shift in diabetes
                prevalence to HCC 18 (with complications)
              </h4>
              <img
                src="../assets/images/coding-chart-2.png"
                alt="30% prevalence accuracy improvement"
                width="100%"
              />
            </div>
          </template>
        </FlipCardComponent>
        <FlipCardComponent type="quaternary">
          <template v-slot:front>
            Comprehensive visits enhanced by Vatica's workflow will improve
            accuracy of population disease burden
          </template>
          <template v-slot:back>
            <div class="coding-content__card">
              <h4 class="turquoise-text">
                Comprehensive visits enhanced by Vatica's workflow will improve accuracy of population disease burden
              </h4>
              <img
                src="../assets/images/coding-chart-3.png"
                alt="30% prevalence accuracy improvement"
                width="100%"
              />
            </div>
          </template>
        </FlipCardComponent>
      </div>
    </div>
    <DotsNavigation :dots="dots"></DotsNavigation>
  </div>
</template>

<script setup>
import FlipCardComponent from '@/components/FlipCardComponent.vue';
import NavComponent from '@/components/NavComponent.vue';
import DotsNavigation from '@/components/DotsNavigation.vue';

const dots = ['/gap-closures', '/coding-accuracy', '/moving-needle'];
</script>

<style lang="scss" scoped>
.coding-content {
  padding: 60px 14px;

  &__title {
    margin-bottom: 30px;
  }

  &__content {
    display: flex;
    gap: 16px;

    .column {
      width: calc((100% / 2) - 8px);

      &--large .flip-card {
        height: 780px;
      }

      &--vertical-half {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .flip-card {
          height: calc((100% / 2) - 8px);
        }
      }
    }
  }

  &__card {
    background: $white;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;

    h4 {
        margin-bottom: 16px;
    }

    p {
        margin: 0 0 30px;
    }
  }
}
</style>

<style lang="scss">
.coding-content {
  .flip-card__front {
    font-size: 28px;
    line-height: 32px;
  }
}
</style>
