<template>
  <NavComponent></NavComponent>
  <div class="main-content pepn-content">
    <h1 class="pepn__title" data-aos="fade-up">Pre-encounter provider notifications sample</h1>
    <CardComponent data-aos="zoom-in" data-aos-delay="200">
        <img src="../assets/images/pre-encounter.png" alt="Pre-Encounter Provider Notifications" width="100%">
    </CardComponent>
    <CopyrightComponent class="copyright--floating"></CopyrightComponent>
    <DotsNavigation :dots="dots"></DotsNavigation>
  </div>
</template>

<script setup>
import NavComponent from '../components/NavComponent.vue';
import CardComponent from '@/components/CardComponent.vue';
import DotsNavigation from '@/components/DotsNavigation.vue';
import CopyrightComponent from '@/components/CopyrightComponent.vue';

const dots = ['/workflow', '/pepn', '365-demo', '/integration', '/integration-workflow'];
</script>

<style lang="scss" scoped>
.pepn-content {
  padding: 60px 14px;
}

.pepn {
  &__title {
    margin-bottom: 30px;
  }
}

.copyright--floating {
  bottom: toRem(65);
}
</style>
