<template>
  <NavComponent></NavComponent>
  <div class="main-content case-content">
    <div class="case-wrapper">
      <div class="column">
        <card-component
          direction="column"
          class="case__details"
          data-aos="zoom-in"
        >
          <img
            class="case__author-logo"
            src="@/assets/images/UCHealth.png"
            alt="Catholic Medical Partners"
          />
          <h2 class="case__details-title title">
            A formula for widespread provider adoption​
          </h2>
          <NumericList :items="listItems"></NumericList>
        </card-component>
      </div>
      <div class="column" data-aos="zoom-in">
        <div class="two-columns">
          <card-component class="align-center">
            <div class="statistic-number">
              <CounterComponent
                number="93"
                suffix="%"
                duration="2500"
              ></CounterComponent>
            </div>
            <p>93% of providers using Vatica (95 of 101 providers)​</p>
          </card-component>
          <card-component class="align-center">
            <div class="statistic-number blue-text">
              <CounterComponent
                number="19"
                suffix="%"
                duration="2500"
              ></CounterComponent>
            </div>
            <p>19% YoY increase in signed visits​</p>
          </card-component>
        </div>
        <div class="two-columns">
          <card-component class="align-center">
            <div class="statistic-number orange-text">
              <CounterComponent
                number="100"
                suffix="%"
                duration="2500"
              ></CounterComponent>
            </div>
            <p>
              100% integration with Vatica Connect: all providers trained and
              using the application​
            </p>
          </card-component>
          <card-component class="align-center">
            <div class="statistic-number turquoise-text">100K+</div>
            <p>$100,000+ earned incentive​s</p>
          </card-component>
        </div>
        <card-component direction="column" class="testimonial-card">
          <TestimonialComponent
            name="Kyle Kaufman, MD"
            description="Internist and Associate Professor of Clinical at University of Cincinnati College of Medicine"
            :image="require('@/assets/images/kyle-kaufman.png')"
          >
            "I’ve been most impressed with Vatica’s ‘at-the-elbow’ customer
            service. It’s been such an easy process for our clinicians to
            onboard. The team is willing to come out—even on short notice—to
            make sure that the clinicians can log on and use the product with a
            real case. When they’re done, the clinicians have a good
            understanding of how to use the product to its fullest extent."
          </TestimonialComponent>
        </card-component>
      </div>
    </div>
    <CopyrightComponent class="copyright--floating"></CopyrightComponent>
    <DotsNavigation :dots="dots"></DotsNavigation>
  </div>
</template>

<script setup>
import NavComponent from '@/components/NavComponent.vue';
import CardComponent from '@/components/CardComponent.vue';
import TestimonialComponent from '@/components/TestimonialComponent.vue';
import DotsNavigation from '@/components/DotsNavigation.vue';
import CounterComponent from '@/components/CounterComponent.vue';
import CopyrightComponent from '@/components/CopyrightComponent.vue';
import NumericList from '@/components/NumericList.vue';

const dots = [
  '/cases/uc-health',
  '/cases/guthrie',
  '/cases/yale',
  '/cases/general-pc',
  '/cases/catholic-medical-partners',
  '/cases/mercyhealth',
];

const listItems = [
  'Introduction of Vatica via email or large meetings doesn’t work. <span class="emph blue-text">Small meetings with 3-6 physicians are more effective.</span>',
  '<span class="emph blue-text">Frame the discussion with an overview of risk adjustment</span>, the current regulatory environment and how Vatica provides hands-on assistance to physicians. Dr. Kaufman found that this sets the stage to explain the Vatica process and get buy-in.',
  'After physicians understand why and how, <span class="emph blue-text">“seal the deal” with details on incentives earned</span> and how payers sponsor Vatica services free of charge to providers.',
  '<span class="emph blue-text">Share visuals:</span> Vatica has presentation templates and a lot of data to use.',
];
</script>

<style lang="scss" scoped>
.three-column {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;

  .card {
    width: calc((100% / 3) - 16px);
    padding-left: 24px;
    padding-right: 24px;
    min-height: 357px;
    box-sizing: border-box;
    align-items: flex-start;
    padding-top: 70px;
  }
}

.card {
  flex-grow: 1;
}
</style>

<style lang="scss">
.case-content {
  .case-wrapper {
    display: grid;
    grid-template-columns: 456px 1fr;
    gap: 16px;
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }

  .card__content {
    text-align: left;
    font-weight: 400;
    align-items: flex-start;
  }

  p {
    margin: 0;
  }
}

.case {
  &__details {
    .image-chart {
      margin-top: 45px;
    }

    &-title.title {
      font-size: toRem(22);
      color: $blue;
      width: 85%;
    }
  }

  &__description {
    max-width: 92%;
    color: $dark-gray;
  }
}

.three-column,
.two-columns {
  .card__content {
    font-weight: 500;
  }
}

.emph {
  font-weight: 500;
}
</style>
