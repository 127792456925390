<template>
  <NavComponent></NavComponent>
  <div class="main-content case-content case-content--catholic">
    <div class="case-wrapper">
      <div class="column">
        <card-component direction="column" class="case__details" data-aos="zoom-in">
          <img
            class="case__author-logo"
            src="@/assets/images/catholic-medical-partners-logo.png"
            alt="Catholic Medical Partners"
          />
          <p class="case__description">
            Catholic Medical Partners is an IPA in Western New York with 65
            primary care practices and 900 providers.
          </p>
          <img
            class="image-chart"
            src="@/assets/images/catholic-chart.png"
            alt="Diabetes specifity Chart"
          />
        </card-component>
      </div>
      <div class="column" data-aos="zoom-in">
        <div class="two-columns">
          <card-component>
            <div class="statistic-number">
              <CounterComponent
                number="73"
                suffix="%"
                duration="4500"
              ></CounterComponent></div>
            <p>Vatica visits completed for 73% of 47,059 eligible patients</p>
          </card-component>
          <card-component>
            <div class="statistic-number blue-text"><CounterComponent
                number="42"
                suffix="%"
                duration="2500"
              ></CounterComponent></div>
            <p>
              Vatica responsible for a 42% increase in vascular disease cases
              documented
            </p>
          </card-component>
        </div>
        <div class="two-columns">
          <card-component>
            <div class="statistic-number orange-text"><CounterComponent
                number="33"
                suffix="%"
                duration="2500"
              ></CounterComponent></div>
            <p>Vatica responsible for a 33% increase in CKD cases documented</p>
          </card-component>
          <card-component>
            <div class="statistic-number turquoise-text">
              73K+</div>
            <p>Vatica escalated 73,456 open care gaps for physician review</p>
          </card-component>
        </div>
        <card-component direction="column" class="testimonial-card">
          <TestimonialComponent
            name="Margaret Paroski, MD"
            :image="require('@/assets/images/margaret-paroski-portrait.png')"
          >
            "My favorite part about working with Vatica is the team is always
            with me. Lots of times you hire people to help you, they're there in
            the beginning, and then they become vaporware. Vatica is always so
            responsive. It's been incredibly important to us."
          </TestimonialComponent>
        </card-component>
      </div>
    </div>
    <DotsNavigation :dots="dots"></DotsNavigation>
  </div>
</template>

<script setup>
import NavComponent from '@/components/NavComponent.vue';
import CardComponent from '@/components/CardComponent.vue';
import TestimonialComponent from '@/components/TestimonialComponent.vue';
import DotsNavigation from '@/components/DotsNavigation.vue';
import CounterComponent from '@/components/CounterComponent.vue';

const dots = ['/cases/paul-linder', '/cases/catholic-medical-partners', '/cases/mercyhealth', '/cases/communicare'];
</script>

<style lang="scss" scoped>
.two-columns {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;

  .card {
    width: calc((100% / 2) - 16px);
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }
}

.card {
  flex-grow: 1;
}
</style>

<style lang="scss">
.case-content {
  &--catholic {
    .two-columns .card__content {
      align-items: center;
      font-weight: 500;
    }
  }
}

.case__author-logo {
    margin-bottom: 35px;
}
</style>
