<template>
  <div class="container settings">
    <h1 class="settings__title" data-aos="fade-up">Settings</h1>
    <div class="global-message" v-if="gMessage">{{ gMessage }}</div>
    <div class="main-nav" data-aos="fade-in" data-aos-delay="500">
      <ButtonComponent type="primary" path="/new-meeting"
        >Create new meeting</ButtonComponent
      >
      <ButtonComponent type="link" :is-link="false" @click.once="clearMeetings"
        >Clear all meetings</ButtonComponent
      >
    </div>
    <div class="settings__meetings meetings">
      <h3 class="meetings__title" data-aos="fade-in" data-aos-delay="500">
        Active Meetings
      </h3>
      <div class="meetings__table">
        <MeetingsTable></MeetingsTable>
      </div>
    </div>
  </div>
  <LoaderComponent v-if="!contentCached"></LoaderComponent>
</template>

<script setup>
import LoaderComponent from '@/components/LoaderComponent.vue';
import { computed, onMounted, onUpdated, ref } from 'vue';
import { useStore } from 'vuex';
import ButtonComponent from '../components/ButtonComponent.vue';
import MeetingsTable from '../components/MeetingsTableComponent.vue';
import useMeetings from '../composables/meetings.js';

const { resetMeetings } = useMeetings();
const clearMeetings = () => {
  resetMeetings();
};
const contentCached = ref(false);
const store = useStore();
const gMessage = computed(() => {
  return store.state.globalMessage;
});

onMounted(() => {
  if (store.state.globalMessage) {
    setTimeout(() => {
      store.commit('setGlobalMessage', '');
    }, 3000);
  }

  let cookieInterval = setInterval(() => {
    if (document.cookie.split(";").some((item) => item.trim().startsWith("content_cached="))) {
      contentCached.value = true;
      clearInterval(cookieInterval);
    }
  }, 1500);
});
</script>

<style lang="scss" scoped>
.settings {
  &__title {
    margin-bottom: 30px;
  }
}

.global-message {
  padding: 10px 10px;
  background: $orange;
  color: $white;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 500;
}

.main-nav {
  display: flex;
  margin-bottom: 60px;
  align-items: center;
  gap: 40px;

  .button {
    width: auto;
  }
}

.meetings {
  &__title {
    margin-bottom: 40px;
  }
}
</style>
