<template>
  <NavComponent></NavComponent>
  <div class="main-content workflow-content">
    <h1 class="workflow__title" data-aos="fade-up">Workflow – VaticaOne</h1>
    <CardComponent>
      <img
        class="workflow__dot"
        src="../assets/images/workflow-dot.png"
        alt="90+% of work is done by Vatica"
        width="142"
        data-aos="fade-in" data-aos-delay="1000"
      />
      <div class="bubbles-wrapper">
        <div class="row">
          <BubbleComponentVue
            title="Patient visit"
            type="secondary"
            index="2"
            direction="top"
            data-aos="zoom-in" data-aos-delay="250"
          >
            <div class="workflow__content">
              <div class="column">
                <h6 class="orange-text">PCP reviews PEPN</h6>
                <ul>
                  <li>EMR + Health plan supported conditions</li>
                  <li>Open care gaps</li>
                  <li>Unreconciled meds</li>
                </ul>
              </div>
              <div class="column">
                <h6 class="orange-text">ZERO disruption to</h6>
                <ul>
                  <li>Clinical hours</li>
                  <li>Patient experience</li>
                  <li>Claims filing & reimbursement</li>
                </ul>
              </div>
            </div>
          </BubbleComponentVue>

          <BubbleComponentVue
            class="text-nowrap"
            title="Coding exercise"
            type="secondary"
            index="4"
            direction="top"
            data-aos="zoom-in" data-aos-delay="450"
          >
            <div class="workflow__content workflow__content--column pb-column">
              <div class="column">
                <h6 class="orange-text">PCP completes</h6>
                <p>for each condition and e-signs record</p>
              </div>
              <div class="column">
                <h6 class="orange-text">2&ndash;4 minutes</h6>
                <p>per patient per year</p>
              </div>
            </div>
          </BubbleComponentVue>
        </div>
        <div class="row">
          <BubbleComponentVue
            class="text-nowrap"
            title="Pre-visit prep"
            type="primary"
            index="1"
            data-aos="zoom-in"
          >
            <div class="workflow__content workflow__content--three-quarters workflow__content--space-border">
              <div class="column">
                <h6 class="turquoise-text">Scheduling</h6>
                <ul>
                  <li>Member engagement</li>
                  <li>Sync in-scope appointments</li>
                </ul>
              </div>
              <div class="column">
                <h6 class="turquoise-text">
                  Shortly before visit, Vatica nurse aggregates detailed patient
                  profile
                </h6>
                <ul>
                  <li>Progress notes</li>
                  <li>Consult notes</li>
                  <li>Imaging & labs</li>
                  <li>Demographics</li>
                  <li>Histories</li>
                  <li>Medications</li>
                  <li>Health plan data</li>
                  <li>Scanned documents</li>
                </ul>
              </div>
            </div>
          </BubbleComponentVue>

          <BubbleComponentVue
            class="text-nowrap"
            title="Post-visit prep"
            type="primary"
            index="3"
            data-aos="zoom-in" data-aos-delay="350"
          >
            <div class="workflow__content">
              <div>
                <h6 class="turquoise-text">
                  Shortly after visit, Vatica nurse
                </h6>
                <ul>
                  <li>Reviews DOS note, interim labs, etc.</li>
                  <li>Consolidates supporting data</li>
                  <li>Prepares efficient coding exercise</li>
                  <li>Notifies treating PCP, NP, PA</li>
                </ul>
              </div>
            </div>
          </BubbleComponentVue>

          <BubbleComponentVue
            class="text-nowrap"
            title="Close the loop"
            type="primary"
            index="5"
            data-aos="zoom-in" data-aos-delay="550"
          >
            <div class="workflow__content workflow__content--space-border">
              <div class="column">
                <h6 class="turquoise-text">To PCP</h6>
                <ul>
                  <li>Problem List Support</li>
                  <li>Medical Record (PDF)</li>
                  <li>Program Revenue</li>
                </ul>
              </div>
              <div class="column">
                <h6 class="turquoise-text">To Health Plan</h6>
                <ul>
                  <li>Diagnosis Submission Data</li>
                  <li>Medical Record (PDF)</li>
                </ul>
              </div>
            </div>
          </BubbleComponentVue>
        </div>
      </div>
      <div class="legends" data-aos="fade-in" data-aos-delay="1150" data-aos-anchor=".main-content">
        <div class="legend legend--primary">PCP</div>
        <div class="legend legend--secondary">Vatica</div>
      </div>
    </CardComponent>
    <CopyrightComponent class="copyright--floating"></CopyrightComponent>
    <DotsNavigation :dots="dots"></DotsNavigation>
  </div>
</template>

<script setup>
import BubbleComponentVue from '@/components/BubbleComponent.vue';
import NavComponent from '../components/NavComponent.vue';
import CardComponent from '@/components/CardComponent.vue';
import DotsNavigation from '@/components/DotsNavigation.vue';
import CopyrightComponent from '@/components/CopyrightComponent.vue';

const dots = ['/workflow', '/pepn', '365-demo', '/integration', '/integration-workflow'];
</script>

<style lang="scss" scoped>
.workflow-content {
  padding: 60px 14px;

  .card {
    position: relative;
    padding-top: 190px;
  }

  .bubble-collapsible {
    max-width: 345px;
  }

  .row {
    display: flex;
    justify-content: center;
    gap: 45px;

    &:not(:last-child) {
      margin-bottom: 54px;
    }
  }
}

.workflow {
  &__dot {
    position: absolute;
    top: 25px;
    right: 25px;
  }

  &__title {
    margin-bottom: 30px;
  }

  &__content {
    display: flex;
    gap: 8px;
    font-weight: 400;
    font-size: $fs-deci;
    justify-content: center;

    p {
      margin: 0;
    }

    .column {
      width: calc(50% - 8px);
    }

    ul {
      padding-right: 0;
      padding-left: 18px;
      font-size: $fs-deci;
      line-height: 16px;
      font-weight: 400;

      li {
        margin-bottom: 8px;
      }
    }

    &--column {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      .column {
        width: 100%;
      }
    }
  }
}

.legends {
  display: flex;
  gap: 32px;
  justify-content: flex-end;
}

.legend {
  display: inline-flex;

  &::before {
    content: '';
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 5px;
  }

  &--primary::before {
    background: $blue;
  }

  &--secondary::before {
    background: $turquoise;
  }
}

.bubbles-wrapper {
  padding-bottom: 284px;
}

.pb-column {
  padding-bottom: 50px;
}

.copyright--floating {
  bottom: toRem(65);
}
</style>

<style lang="scss">
.workflow-content {
  .card__content {
    display: block;
  }
}

.workflow {
  &__content {
    &--three-quarters .column {
      &:first-child {
        width: 45%;
      }

      &:last-child {
        width: 55%;
      }
    }
    
    &--space-border {
      padding: 0 15px;
    }
  }
}
</style>
