<template>
  <div class="setup">
    <div class="setup__content">
      <h1 class="setup__title" data-aos="fade-up">Meeting customization</h1>
      <div class="setup__form">
        <form @submit.prevent="saveMeeting" data-aos="fade-in">
          <div class="field">
            <label for="provider-name">Input provider name</label>
            <input
              type="text"
              class="input-text"
              placeholder="Type name here"
              id="provider-name"
              v-model="provider_name"
              required
            />
          </div>
          <div class="field">
            <label for="payer-networks"
              >Input associated payer network(s)</label
            >
            <p class="field__note">Enter up to nine networks (one per line)</p>
            <textarea
              name="textarea"
              cols="30"
              rows="9"
              id="payer-networks"
              v-model="payer_network"
              placeholder="Type name(s) here"
              required
              @keydown.enter="checkPayerNumber"
            >
  Type name(s) here</textarea
            >
          </div>
          <div class="field">
            <label for="covered-lives"
              >Input number of covered/sponsored lives</label
            >
            <input
              type="number"
              class="input-text"
              placeholder="Type number here"
              id="covered-lives"
              v-model="covered_lives"
              required
            />
          </div>
          <toggle-component class="setup__toggle" v-model="show_bvc" :defaultValue="show_bvc"
            >Show VBC gainshare PMPY</toggle-component
          >
          <p class="setup__error">{{ message }}</p>
          <ButtonComponent class="setup__submit" type="primary" :is-link="false"
            >Save and start meeting</ButtonComponent
          >
        </form>
        <ButtonComponent type="link" path="/settings"
          >Back to settings</ButtonComponent
        >
        <CopyrightComponent></CopyrightComponent>
      </div>
    </div>
    <div class="setup__media">
      <img
        src="../assets/images/people.png"
        alt=""
        width="550"
        data-aos="zoom-in"
      />
      <LogoComponent width="265" height="54"></LogoComponent>
    </div>
  </div>
  <LoaderComponent v-if="showLoader"></LoaderComponent>
</template>

<script setup>
import ToggleComponent from '../components/ToggleComponent.vue';
import ButtonComponent from '../components/ButtonComponent.vue';
import LogoComponent from '../components/LogoComponent.vue';
import LoaderComponent from '../components/LoaderComponent.vue';
import useMeetings from '../composables/meetings.js';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import CopyrightComponent from '@/components/CopyrightComponent.vue';
const route = useRoute();

const { getMeetingById, updateMeeting } = useMeetings();
const meeting = getMeetingById(route.params.id);
console.log(meeting);
const message = ref('');
const provider_name = defineModel('provider_name');
const payer_network = defineModel('payer_network');
const covered_lives = defineModel('covered_lives');
const show_bvc = defineModel('show_bvc');
const showLoader = ref(false);

const reformatPayerNetwork = (data) => {
  return data.replaceAll(',', '\n');
};

provider_name.value = meeting.provider_name;
payer_network.value = reformatPayerNetwork(meeting.payer_network.toString());
covered_lives.value = meeting.covered_lives;
show_bvc.value = meeting.show_bvc;

const formatPayerNetwork = (data) => {
  return data.split(/\r?\n|\r|\n/g);
};

const saveMeeting = (e) => {
  showLoader.value = true;
  const meeting = {
    id: route.params.id,
    provider_name: provider_name.value,
    payer_network: formatPayerNetwork(payer_network.value),
    covered_lives: covered_lives.value,
    show_bvc: e.target.querySelector('#toggle').checked,
  };

  updateMeeting(meeting);

  showLoader.value = false;
};

const checkPayerNumber = (e) => {
  let rowNumber = formatPayerNetwork(payer_network.value).length;

  if (rowNumber > 8) {
    e.preventDefault();
    return;
  }
};
</script>

<style lang="scss" scoped>
.setup {
  display: flex;
  justify-content: space-between;

  &__content {
    padding: 60px 134px 60px 60px;
    width: calc(100% - (594px + 134px));
  }

  &__title {
    margin-bottom: 42px;
  }

  &__toggle {
    justify-content: center;
    margin-bottom: 36px;
  }

  &__submit {
    margin: 0px 0 10px;
  }

  &__media {
    background-color: $bg-gray;
    background-image: url('../assets/images/circles-bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 594px;
  }

  &__error {
    color: red;
  }

  .button--link {
    width: 100%;
  }
}
</style>
