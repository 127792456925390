<template>
  <NavComponent></NavComponent>
  <div class="main-content case-content case-content--catholic">
    <div class="case-wrapper">
      <div class="column">
        <card-component
          direction="column"
          class="case__details"
          data-aos="zoom-in"
        >
          <img
            class="case__author-logo"
            src="@/assets/images/guthrie-logo.png"
            alt="Catholic Medical Partners"
          />
          <h2 class="case__details-title title">
            Higher provider engagement drives more resources
          </h2>
          <p class="case__description">
            “Like many practices these days, we are resource starved. In the
            future, we plan to use this revenue to increase our pharmacy and
            nursing staff. Having a full team is so helpful to PCPs and relieves
            a lot of the time burden they face.”​
          </p>
          <p class="text-right w-full">— Dr. Rai&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
          <img
            class="image-chart"
            src="@/assets/images/guthrie-chart.jpg"
            alt="Diabetes specifity Chart"
          />
        </card-component>
      </div>
      <div class="column" data-aos="zoom-in">
        <card-component direction="column" class="mb-md text-box text-box--start">
          <p>
            <span class="text-box__title">Share incentive with PCPs for rapid adoption</span><br />
            Since beginning with Vatica in October 2022, Dr. Rai championed the
            effort to share half the incentive with treating providers. He
            worked with multiple teams at Lourdes Medical Group to implement a
            tracking and payment process.
          </p>
          <p>
            <span class="text-box__title">Use incentives to hire needed resources</span><br />
            High provider engagement resulted in considerable incentives that
            enabled Lourdes Medical Group to hire a badly needed social worker
            to assist patients with social determinants of health and related
            issues.
          </p>
        </card-component>

        <card-component direction="column" class="testimonial-card">
          <TestimonialComponent
            name="Jagraj Rai, MD"
            description="President"
            :image="require('@/assets/images/jagraj-rai.png')"
          >
            "I felt it was vital to compensate physicians for their time in
            reviewing and signing the Vatica record,” Dr. Rai explained. “We
            made that first payment in December 2023, and it’s had a huge impact
            on participation."
          </TestimonialComponent>
        </card-component>
      </div>
    </div>
    <CopyrightComponent class="copyright--floating"></CopyrightComponent>
    <DotsNavigation :dots="dots"></DotsNavigation>
  </div>
</template>

<script setup>
import NavComponent from '@/components/NavComponent.vue';
import CardComponent from '@/components/CardComponent.vue';
import TestimonialComponent from '@/components/TestimonialComponent.vue';
import DotsNavigation from '@/components/DotsNavigation.vue';
import CopyrightComponent from '@/components/CopyrightComponent.vue';

const dots = [
  '/cases/uc-health',
  '/cases/guthrie',
  '/cases/yale',
  '/cases/general-pc',
  '/cases/catholic-medical-partners',
  '/cases/mercyhealth',
];
</script>

<style lang="scss" scoped>
.two-columns {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;

  .card {
    width: calc((100% / 2) - 16px);
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }
}

.card {
  flex-grow: 1;
}

.text-box span {
  font-size: $fs-hecto;
  color: $blue;
  font-weight: 500;
}
</style>
