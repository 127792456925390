<template>
  <div class="collapsible">
    <router-link
      class="collapsible__button"
      :to="options[0].path"
      v-if="!hasChildren"
      >{{ title }}</router-link
    >
    <button
      class="collapsible__button collapsible__button--has-children"
      :class="{'active': hasActiveChildren(options)}"
      @click="toggleCollapse"
      v-if="hasChildren"
    >
      {{ title }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M11.5714 5L13 6.42857L8 11.4286L3 6.42857L4.42857 5L8 8.57143L11.5714 5Z"
          fill="white"
        />
      </svg>
    </button>
    <div class="collapsible__content collapsible__content--has-children" v-if="hasChildren">
      <ul v-show="collapsedState">
        <li v-for="(option, index) in options" :key="index">
          <router-link :to="option.path">{{ option.text }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

const props = defineProps(['title', 'options', 'hasChildren']);
const collapsed = ref(true);
const location = useRoute();
const collapsedState = computed(() => {
  return !collapsed.value || hasActiveChildren(props.options);
})

function toggleCollapse() {
  collapsed.value = !collapsed.value;
}

function hasActiveChildren(options) {
  let route = options.find((option) => {
    return option.path === location.fullPath;
  })
  
  return route;
}
</script>

<style lang="scss" scoped>
.collapsible {
  width: 100%;
  margin-bottom: 18px;

  &__button {
    cursor: pointer;
    background: none;
    color: $white;
    padding: 10px 7px;
    width: 100%;
    box-shadow: none;
    border: none;
    font-size: $fs-deci;
    text-align: left;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;

    &.active,
    &.router-link-exact-active {
      background: $orange;
      box-sizing: border-box;
      color: $white;
      border-radius: 6px;
    }
  }

  &__content {
    padding: 0 20px 0;
    transition: max-height 0.2s ease-out;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-top: 16px;
      }

      a {
        color: rgba(255, 255, 255, 0.6);
        text-decoration: none;
        font-size: $fs-centi;
        display: block;
        font-weight: 500;

        &.router-link-exact-active {
          color: $white;
        }
      }
    }
  }
}
</style>
