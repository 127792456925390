<template>
  <NavComponent></NavComponent>
  <div class="main-content gap-content">
    <h1 class="gap__title" data-aos="fade-up">The numbers behind our clients’ success</h1>
    <h4 class="gap__subtitle" data-aos="fade-in" data-aos-delay="200">
      From gap closure rates to accuracy improvement and more, Vatica improves
      clinical and financial outcomes for practices.
    </h4>
    <CardComponent direction="column" contentAlign="left" data-aos="zoom-in" data-aos-delay="350">
      <h4 class="turquoise-text card__title">
        2022 Case Study: Vatica Gap Closure
      </h4>
      <p>Compared to 4 Star Rating; Claims paid thru 1/31/2023</p>
      <img
        src="../assets/images/gap-chart.png"
        alt="2022 Case Study: Vatica Gap Closure"
        width="100%"
      />
    </CardComponent>
    <DotsNavigation :dots="dots"></DotsNavigation>
  </div>
</template>

<script setup>
import NavComponent from '../components/NavComponent.vue';
import CardComponent from '@/components/CardComponent.vue';
import DotsNavigation from '@/components/DotsNavigation.vue';

const dots = ['/gap-closures', '/coding-accuracy', '/moving-needle'];
</script>

<style lang="scss" scoped>
.gap-content {
  padding: 60px 14px;
}

.gap {
  &__title,
  &__subtitle {
    margin-bottom: 30px;
  }

  &__subtitle {
    max-width: 65%;
  }
}

.card__title {
  margin-bottom: 0;
}

p {
  margin: 0 0 40px;
}
</style>

<style></style>
