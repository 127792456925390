import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import Settings from './pages/Settings.vue';
import Setup from './pages/Setup.vue';
import Welcome from './pages/Welcome.vue';
import ValueProp from './pages/ValueProp.vue';
import Why from './pages/Why.vue';
import FinancialOpp from './pages/FinancialOpp.vue';
import Workflow from './pages/Workflow.vue';
import Pepn from './pages/Pepn.vue';
import Well365 from './pages/Well365.vue';
import Integration from './pages/Integration.vue';
import IntegrationWorkflow from './pages/IntegrationWorkflow.vue';
import GapClosures from './pages/GapClosures.vue';
import MovingNeedle from './pages/MovingNeedle.vue';
import CodingAccuracy from './pages/CodingAccuracy.vue';
import EditMeeting from './pages/EditMeeting.vue';
import UCHealthCase from './pages/cases/UCHealth.vue';
import GuthrieCase from './pages/cases/GuthrieCase.vue';
import YaleCase from './pages/cases/YaleCase.vue';
import GeneralPCCase from './pages/cases/GeneralPCCase.vue';
import CatholicCase from './pages/cases/CatholicMedicalPartnersCase.vue';
import MercyhealthCase from './pages/cases/MercyhealthCase.vue';
import NextSteps from './pages/NextSteps.vue';
import './registerServiceWorker';
import store from './store';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/settings', component: Settings, name: 'settings' },
    { path: '/', component: Settings, name: 'settings-home' },
    { path: '/new-meeting', component: Setup, name: 'setup' },
    { path: '/welcome', component: Welcome },
    { path: '/value-prop', component: ValueProp },
    { path: '/why-vatica', component: Why },
    { path: '/financial-opportunity', component: FinancialOpp },
    { path: '/workflow', component: Workflow },
    { path: '/pepn', component: Pepn },
    { path: '/365-demo', component: Well365 },
    { path: '/integration', component: Integration },
    { path: '/integration-workflow', component: IntegrationWorkflow },
    { path: '/cases/uc-health', component: UCHealthCase },
    { path: '/cases/guthrie', component: GuthrieCase },
    { path: '/cases/yale', component: YaleCase },
    { path: '/cases/general-pc', component: GeneralPCCase },
    { path: '/cases/catholic-medical-partners', component: CatholicCase },
    { path: '/cases/mercyhealth', component: MercyhealthCase },
    { path: '/gap-closures', component: GapClosures },
    { path: '/moving-needle', component: MovingNeedle },
    { path: '/coding-accuracy', component: CodingAccuracy },
    { path: '/next-steps', component: NextSteps },
    { path: '/meetings/edit/:id', component: EditMeeting, name: 'edit' },
  ],
});

router.beforeEach((to, from) => {
  if (
    store.state.currentMeeting.id === undefined &&
    to.name !== 'settings' &&
    to.name !== 'settings-home' &&
    to.name !== 'edit' &&
    to.name !== 'setup'
  ) {
    store.commit('setGlobalMessage', 'You need to start a meeting to view the content.');
    router.push('/settings');
  }
});

createApp(App).use(store).use(router).mount('#app');
